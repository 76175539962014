import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import FeaturedBlogSection from "../components/pages/Blogs/FeaturedBlogSection"
import PostsWithSearchPaginationSection from "../components/common/PostsWithSearchPaginationSection"
import RecommendedBlogs from "../components/common/RecommendedBlogs"
import BusinessModelsWithFormSection from "../components/common/BusinessModelsWithFormSection"
import { blogPosts } from "../Content/Blogs"
import { blogsPostTypeSlug } from "../Content/Global"

const Blogs = () => (
  <Layout>
    <Seo
      title="Industry News, Tips and Software Updates"
      description={`Explore our blog for tips and updates on expense tracking, tax management, and financial tools. Optimise your financial strategy with Synkli.`}
    />

    <FeaturedBlogSection />

    <PostsWithSearchPaginationSection
      title={`All Blogs`}
      searchPlaceholder={`Search Blogs`}
      posts={blogPosts}
      posttypeSlug={blogsPostTypeSlug}
    />

    <RecommendedBlogs />

    <div className="pb-10">
      <BusinessModelsWithFormSection
        title={"Synkli – Your Ultimate Business Management Partner"}
        description={`Elevate your business with Synkli, your comprehensive business management solution. Streamline operations, enhance efficiency, and drive growth with our intuitive software.`}
      />
    </div>
  </Layout>
)

export default Blogs
